"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _countryCodeLookup = _interopRequireDefault(require("country-code-lookup"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// this data come from https://docs.stripe.com/billing/customer/tax-ids
var taxtIdTypes = [{
  country: 'Andorra',
  type: 'ad_nrt',
  description: 'Andorran NRT number'
}, {
  country: 'Argentina',
  type: 'ar_cuit',
  description: 'Argentinian tax ID number'
}, {
  country: 'Australia',
  type: 'au_abn',
  description: 'Australian Business Number (AU ABN)'
}, {
  country: 'Australia',
  type: 'au_arn',
  description: 'Australian Taxation Office Reference Number'
}, {
  country: 'Austria',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Bahrain',
  type: 'bh_vat',
  description: 'Bahraini VAT Number'
}, {
  country: 'Belarus',
  type: 'by_tin',
  description: 'Belarus TIN Number'
}, {
  country: 'Belgium',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Bolivia',
  type: 'bo_tin',
  description: 'Bolivian tax ID'
}, {
  country: 'Brazil',
  type: 'br_cnpj',
  description: 'Brazilian CNPJ number'
}, {
  country: 'Brazil',
  type: 'br_cpf',
  description: 'Brazilian CPF number'
}, {
  country: 'Bulgaria',
  type: 'bg_uic',
  description: 'Bulgaria Unified Identification Code'
}, {
  country: 'Bulgaria',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Canada',
  type: 'ca_bn',
  description: 'Canadian BN'
}, {
  country: 'Canada',
  type: 'ca_gst_hst',
  description: 'Canadian GST/HST number'
}, {
  country: 'Canada',
  type: 'ca_pst_bc',
  description: 'Canadian PST number (British Columbia)'
}, {
  country: 'Canada',
  type: 'ca_pst_mb',
  description: 'Canadian PST number (Manitoba)'
}, {
  country: 'Canada',
  type: 'ca_pst_sk',
  description: 'Canadian PST number (Saskatchewan)'
}, {
  country: 'Canada',
  type: 'ca_qst',
  description: 'Canadian QST number (Québec)'
}, {
  country: 'Chile',
  type: 'cl_tin',
  description: 'Chilean TIN'
}, {
  country: 'China',
  type: 'cn_tin',
  description: 'Chinese tax ID'
}, {
  country: 'Colombia',
  type: 'co_nit',
  description: 'Colombian NIT number'
}, {
  country: 'Costa Rica',
  type: 'cr_tin',
  description: 'Costa Rican tax ID'
}, {
  country: 'Croatia',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Croatia',
  type: 'hr_oib',
  description: 'Croatian Personal Identification Number'
}, {
  country: 'Cyprus',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Czech Republic',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Denmark',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Dominican Republic',
  type: 'do_rcn',
  description: 'Dominican RCN number'
}, {
  country: 'Ecuador',
  type: 'ec_ruc',
  description: 'Ecuadorian RUC number'
}, {
  country: 'Egypt',
  type: 'eg_tin',
  description: 'Egyptian Tax Identification Number'
}, {
  country: 'El Salvador',
  type: 'sv_nit',
  description: 'El Salvadorian NIT number'
}, {
  country: 'Estonia',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'EU',
  type: 'eu_oss_vat',
  description: 'European One Stop Shop VAT number for non-Union scheme'
}, {
  country: 'Finland',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'France',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Georgia',
  type: 'ge_vat',
  description: 'Georgian VAT'
}, {
  country: 'Germany',
  type: 'de_stn',
  description: 'German Tax Number (Steuernummer)'
}, {
  country: 'Germany',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Greece',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Hong Kong',
  type: 'hk_br',
  description: 'Hong Kong BR number'
}, {
  country: 'Hungary',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Hungary',
  type: 'hu_tin',
  description: 'Hungary tax number (adószám)'
}, {
  country: 'Iceland',
  type: 'is_vat',
  description: 'Icelandic VAT'
}, {
  country: 'India',
  type: 'in_gst',
  description: 'Indian GST number'
}, {
  country: 'Indonesia',
  type: 'id_npwp',
  description: 'Indonesian NPWP number'
}, {
  country: 'Ireland',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Israel',
  type: 'il_vat',
  description: 'Israel VAT'
}, {
  country: 'Italy',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Japan',
  type: 'jp_cn',
  description: 'Japanese Corporate Number'
}, {
  country: 'Japan',
  type: 'jp_rn',
  description: 'Japanese Registered Foreign Businesses'
}, {
  country: 'Japan',
  type: 'jp_trn',
  description: 'Japanese Tax Registration Number'
}, {
  country: 'Kazakhstan',
  type: 'kz_bin',
  description: 'Kazakhstani Business Identification Number'
}, {
  country: 'Kenya',
  type: 'ke_pin',
  description: 'Kenya Revenue Authority Personal Identification Number'
}, {
  country: 'Latvia',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Liechtenstein',
  type: 'li_uid',
  description: 'Liechtensteinian UID number'
}, {
  country: 'Liechtenstein',
  type: 'li_vat',
  description: 'Liechtensteinian VAT number'
}, {
  country: 'Lithuania',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Luxembourg',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Malaysia',
  type: 'my_frp',
  description: 'Malaysian FRP number'
}, {
  country: 'Malaysia',
  type: 'my_itn',
  description: 'Malaysian ITN'
}, {
  country: 'Malaysia',
  type: 'my_sst',
  description: 'Malaysian SST number'
}, {
  country: 'Malta',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Mexico',
  type: 'mx_rfc',
  description: 'Mexican RFC number'
}, {
  country: 'Moldova',
  type: 'md_vat',
  description: 'Moldova VAT Number'
}, {
  country: 'Morocco',
  type: 'ma_vat',
  description: 'Morocco VAT Number'
}, {
  country: 'Netherlands',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'New Zealand',
  type: 'nz_gst',
  description: 'New Zealand GST number'
}, {
  country: 'Nigeria',
  type: 'ng_tin',
  description: 'Nigerian Tax Identification Number'
}, {
  country: 'Norway',
  type: 'no_vat',
  description: 'Norwegian VAT number'
}, {
  country: 'Norway',
  type: 'no_voec',
  description: 'Norwegian VAT on e-commerce number'
}, {
  country: 'Oman',
  type: 'om_vat',
  description: 'Omani VAT Number'
}, {
  country: 'Peru',
  type: 'pe_ruc',
  description: 'Peruvian RUC number'
}, {
  country: 'Philippines',
  type: 'ph_tin',
  description: 'Philippines Tax Identification Number'
}, {
  country: 'Poland',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Portugal',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Romania',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Romania',
  type: 'ro_tin',
  description: 'Romanian tax ID number'
}, {
  country: 'Russia',
  type: 'ru_inn',
  description: 'Russian INN'
}, {
  country: 'Russia',
  type: 'ru_kpp',
  description: 'Russian KPP'
}, {
  country: 'Saudi Arabia',
  type: 'sa_vat',
  description: 'Saudi Arabia VAT'
}, {
  country: 'Serbia',
  type: 'rs_pib',
  description: 'Serbian PIB number'
}, {
  country: 'Singapore',
  type: 'sg_gst',
  description: 'Singaporean GST'
}, {
  country: 'Singapore',
  type: 'sg_uen',
  description: 'Singaporean UEN'
}, {
  country: 'Slovakia',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Slovenia',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Slovenia',
  type: 'si_tin',
  description: 'Slovenia tax number (davčna številka)'
}, {
  country: 'South Africa',
  type: 'za_vat',
  description: 'South African VAT number'
}, {
  country: 'South',
  type: ' Koreakr_brn',
  description: 'Korean BRN'
}, {
  country: 'Spain',
  type: 'es_cif',
  description: 'Spanish NIF number (previously Spanish CIF number)'
}, {
  country: 'Spain',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Sweden',
  type: 'eu_vat',
  description: 'European VAT number'
}, {
  country: 'Switzerland',
  type: 'ch_uid',
  description: 'Switzerland UID number'
}, {
  country: 'Switzerland',
  type: 'ch_vat',
  description: 'Switzerland VAT number'
}, {
  country: 'Taiwan',
  type: 'tw_vat',
  description: 'Taiwanese VAT'
}, {
  country: 'Tanzania',
  type: 'tz_vat',
  description: 'Tanzania VAT Number'
}, {
  country: 'Thailand',
  type: 'th_vat',
  description: 'Thai VAT'
}, {
  country: 'Turkey',
  type: 'tr_tin',
  description: 'Turkish Tax Identification Number'
}, {
  country: 'Ukraine',
  type: 'ua_vat',
  description: 'Ukrainian VAT'
}, {
  country: 'United Arab Emirates',
  type: 'ae_trn',
  description: 'United Arab Emirates TRN'
}, {
  country: 'United Kingdom',
  type: 'eu_vat',
  description: 'Northern Ireland VAT number'
}, {
  country: 'United Kingdom',
  type: 'gb_vat',
  description: 'United Kingdom VAT number'
}, {
  country: 'United States',
  type: 'us_ein',
  description: 'United States EIN'
}, {
  country: 'Uruguay',
  type: 'uy_ruc',
  description: 'Uruguayan RUC number'
}, {
  country: 'Uzbekistan',
  type: 'uz_tin',
  description: 'Uzbekistan TIN Number'
}, {
  country: 'Uzbekistan',
  type: 'uz_vat',
  description: 'Uzbekistan VAT Number'
}, {
  country: 'Venezuela',
  type: 've_rif',
  description: 'Venezuelan RIF number'
}, {
  country: 'Vietnam',
  type: 'vn_tin',
  description: 'Vietnamese tax ID number'
}];
var _default = exports.default = taxtIdTypes.map(function (type) {
  var _coutries$byCountry;
  return _objectSpread(_objectSpread({}, type), {}, {
    code: (_coutries$byCountry = _countryCodeLookup.default.byCountry(type.country)) === null || _coutries$byCountry === void 0 ? void 0 : _coutries$byCountry.iso2
  });
}).filter(function (type) {
  return type.code;
});
module.exports = exports.default;