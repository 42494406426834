"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.api = api;
exports.appendHash = appendHash;
exports.appendQuery = appendQuery;
exports.appendToUrl = appendToUrl;
exports.applyAlias = applyAlias;
exports.assertPattern = void 0;
exports.base = base;
exports.baseDomain = baseDomain;
exports.cdn = cdn;
exports.crawler = crawler;
exports.create = create;
exports.decodeShortLinkId = decodeShortLinkId;
exports.encodeShortLinkId = encodeShortLinkId;
exports.extendQuery = extendQuery;
exports.extensionStart = extensionStart;
exports.extractPathname = extractPathname;
exports.getPageName = exports.getCorrectUrl = exports.getAbsolutePlayerUrl = exports.extractTutorialIdFromUrl = void 0;
exports.getPathnamePartsLength = getPathnamePartsLength;
exports.getProductQuestionsURL = getProductQuestionsURL;
exports.getQuestionURL = getQuestionURL;
exports.getSpecialPlanLink = exports.getRelativePlayerUrl = void 0;
exports.gravatar = gravatar;
exports.isLive = exports.isExternalUrl = exports.isDev = exports.hostnameToParents = void 0;
exports.isLocalhostIp = isLocalhostIp;
exports.isValidAndWellKnownDomain = isValidAndWellKnownDomain;
exports.landing = landing;
exports.liveEmbed = liveEmbed;
exports.matchPlayerShortLink = exports.matchPlanShortLink = void 0;
exports.matchingDomains = matchingDomains;
exports.node = node;
exports.normaliseDomain = normaliseDomain;
exports.normalizeDomainsGroups = normalizeDomainsGroups;
exports.parseQuery = parseQuery;
exports.parseUrl = parseUrl;
exports.pathParser = pathParser;
exports.publicSuffix = publicSuffix;
exports.queryParser = queryParser;
exports.redirectToSamlUrl = redirectToSamlUrl;
exports.securecdn = securecdn;
exports.status = status;
exports.toAllUrlHostnames = toAllUrlHostnames;
exports.toAllUrlPathnames = toAllUrlPathnames;
exports.validateUrl = validateUrl;
exports.video = video;
var _gravatar = _interopRequireDefault(require("gravatar"));
var _querystring = _interopRequireDefault(require("querystring"));
var _isObject = _interopRequireDefault(require("lodash/isObject"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _uniq = _interopRequireDefault(require("lodash/uniq"));
var _flatten = _interopRequireDefault(require("lodash/flatten"));
var _range = _interopRequireDefault(require("lodash/range"));
var _values = _interopRequireDefault(require("lodash/values"));
var _groupBy = _interopRequireDefault(require("lodash/groupBy"));
var _orderBy = _interopRequireDefault(require("lodash/orderBy"));
var _flowRight = _interopRequireDefault(require("lodash/flowRight"));
var _escapeRegExp = _interopRequireDefault(require("lodash/escapeRegExp"));
var _config = _interopRequireDefault(require("../config"));
var _url = _interopRequireDefault(require("url"));
var _tldjs = _interopRequireDefault(require("tldjs"));
var _normalizeTutorialName = _interopRequireDefault(require("./tutorial/normalizeTutorialName"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var isLive = exports.isLive = _config.default.host === 'www.iorad.com';
var isDev = exports.isDev = _config.default.host.endsWith('.iorad.dev');
var assertPattern = exports.assertPattern = function assertPattern(link) {
  return function (linkToMatch) {
    var r = new RegExp(linkToMatch, 'i');
    return r.test(link);
  };
};
function pathParser(path) {
  if (typeof path === 'string') {
    if (path.indexOf('/') === 0) {
      return path;
    } else {
      return "/".concat(path);
    }
  } else {
    return '';
  }
}
function queryParser(query) {
  if ((0, _isObject.default)(query) && !(0, _isEmpty.default)(query)) {
    var queryString = _querystring.default.stringify(query);
    return "?".concat(queryString);
  } else {
    return '';
  }
}
function parseQuery() {
  var queryString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.search;
  var query = {};
  if (!queryString) return query;
  queryString.replace(/([^?&=]+)=([^&]*)/g, function (_, k, v) {
    var decodedValue = _querystring.default.unescape(v);
    if (query.hasOwnProperty(k)) {
      if (!Array.isArray(query[k])) query[k] = [query[k]];
      query[k].push(decodedValue);
    } else {
      query[k] = decodedValue;
    }
    return decodedValue;
  });
  return query;
}

/**
 * Create an absolute path with host //host:port/path?query so that it should work with both http and https modes
 * @param host
 * @param path
 * @param query
 * @returns {string}
 */
function create(host, path, query, sslHeader) {
  if (typeof sslHeader === 'undefined') {
    return "//".concat(host).concat(pathParser(path)).concat(queryParser(query));
  }
  return (sslHeader === 'on' || sslHeader === true ? 'https://' : 'http://') + "".concat(host).concat(pathParser(path)).concat(queryParser(query));
}
function node(path, query, sslHeader) {
  return create(_config.default.host, path, query, sslHeader);
}
function api(path, query, sslHeader) {
  return node("".concat(pathParser('/api')).concat(pathParser(path)), query, sslHeader);
}

// node without the pathSSL (useful for accessing static content)
function base(path, query, sslHeader) {
  return create(_config.default.host, path, query, sslHeader);
}
function cdn(path, query, sslHeader) {
  return create(_config.default.cdnHost, path, query, sslHeader);
}
function securecdn(path, query, sslHeader) {
  return create(_config.default.cdnHost, path, query, sslHeader);
}
function extensionStart(path, query, sslHeader) {
  return create(_config.default.extensionStartHost, path, query, sslHeader);
}
function gravatar(email, defaultUrl) {
  return _gravatar.default.url(email, {
    d: defaultUrl
  }, true);
}
function liveEmbed(userId) {
  return base("/widgets/live/".concat(userId));
}
function video(path, query, sslHeader) {
  return create(_config.default.videoHost, path, query, sslHeader);
}
function crawler(path, query) {
  return create(_config.default.crawlerHost, path, query, !isDev);
}
function landing(path, query, sslHeader) {
  return create(_config.default.landingHost, path, query, sslHeader);
}
function status(path, query, sslHeader) {
  return create(_config.default.statusHost, path, query, sslHeader);
}
function parseUrl(url) {
  url = url || '';
  if (typeof url !== 'string') {
    throw new Error('src/utils/urls: parseUrl: url is not a string.');
  }
  var parsedUrl = _url.default.parse(url);
  if (!parsedUrl.hostname || parsedUrl.protocol.indexOf('.') !== -1) {
    parsedUrl = _url.default.parse('http://' + url);
    if (!parsedUrl.hostname || parsedUrl.protocol.indexOf('.') !== -1) {
      parsedUrl = _url.default.parse('http:' + url);
    }
  }
  return parsedUrl;
}
var isExternalUrl = exports.isExternalUrl = function isExternalUrl(url) {
  return (url || '').match(/^((https?:\/\/)|(\/\/))/);
};
function extractPathname(url) {
  if (isExternalUrl(url)) {
    return parseUrl(url).pathname;
  }
  // already a pathname
  return url;
}
function getPathnamePartsLength(pathname) {
  if (!pathname || pathname === '/') return 1;
  if (!pathname.startsWith('/')) {
    pathname = "/".concat(pathname);
  }
  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1);
  }
  return pathname.split('/').length;
}
var trimProtocolFunc = function trimProtocolFunc(url) {
  if (url.startsWith('http://')) {
    return url.substr('http://'.length);
  }
  if (url.startsWith('https://')) {
    return url.substr('https://'.length);
  }
  return url;
};
var trimSlashFunc = function trimSlashFunc(url) {
  return url[url.length - 1] === '/' ? url.substr(0, url.length - 1) : url;
};
function validateUrl(url, normilizationOpts) {
  return _tldjs.default.isValid(normaliseDomain(url, normilizationOpts));
}
function isValidAndWellKnownDomain(url) {
  return url ? _tldjs.default.tldExists(normaliseDomain(url)) : false;
}
function normaliseDomain(domain) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$removeWWW = _ref.removeWWW,
    removeWWW = _ref$removeWWW === void 0 ? false : _ref$removeWWW,
    _ref$trimProtocol = _ref.trimProtocol,
    trimProtocol = _ref$trimProtocol === void 0 ? false : _ref$trimProtocol,
    _ref$trimSlash = _ref.trimSlash,
    trimSlash = _ref$trimSlash === void 0 ? false : _ref$trimSlash;
  if (!domain) {
    return domain;
  }
  var result = domain.toLowerCase();
  if (trimProtocol) result = trimProtocolFunc(result);
  if (removeWWW) {
    result = result.startsWith('www.') ? result.slice('www.'.length) : result;
  }
  if (trimSlash) result = trimSlashFunc(result);
  return result;
}
function baseDomain(domain, options) {
  if (!domain) return domain;
  var normalizedDomain = normaliseDomain(domain, options);
  return _tldjs.default.getDomain(normalizedDomain) || normalizedDomain;
}
function publicSuffix(domain, options) {
  var normalizedDomain = normaliseDomain(domain, options);
  return _tldjs.default.getPublicSuffix(normalizedDomain) || '';
}

// Given a list of domains this will return a list of domains we can match
// against which includes the domain as-is, without sub-domains and with port
// (unless the port is 80)
// NOTE: double-coded in iorad-extension
function matchingDomains(urls) {
  var parsedUrls = urls.map(parseUrl);
  return (0, _uniq.default)((0, _flatten.default)(parsedUrls.filter(function (u) {
    return u.hostname;
  }).map(function (u) {
    return (0, _flatten.default)([true, false].map(function (showPort) {
      var port = showPort && u.port && u.port !== '80' ? ':' + u.port : '';
      return [normaliseDomain(u.hostname) + port, baseDomain(u.hostname) + port];
    }));
  })));
}

// Example: 'wwww.drive.google.com' => ['drive.google.com', 'google.com']
function toAllUrlHostnames(urls) {
  if (!Array.isArray(urls)) {
    if (!urls) return [];
    urls = [urls];
  }
  var parsedUrls = urls.map(parseUrl);
  return (0, _uniq.default)((0, _flatten.default)(parsedUrls.filter(function (u) {
    return u.hostname;
  }).map(function (u) {
    var hostname = normaliseDomain(u.hostname, {
      removeWWW: true
    });
    var hostnameParts = hostname.split('.');
    var baseHostname = _tldjs.default.getDomain(hostname) || hostnameParts.slice(-2).join('.');
    var baseHostnameLength = baseHostname.split('.').length;
    var subdomains = (0, _range.default)(hostnameParts.length - baseHostnameLength).map(function (i) {
      return hostnameParts.slice(i, hostnameParts.length).join('.');
    });
    return subdomains.concat([baseHostname]);
  })));
}

// pathname format: '/', '/foo', '/foo/bar'
// e.g. '/foo/bar/some' => ['/', '/foo', '/foo/bar', '/foo/bar/some']
function toAllUrlPathnames(urlPathname) {
  var urlPathnameParts = urlPathname === '/' ? [] : urlPathname.slice(1).split('/');
  var allUrlPathnames = ['/'].concat(_toConsumableArray(urlPathnameParts.map(function (val, i) {
    return '/' + urlPathnameParts.slice(0, i + 1).join('/');
  })));
  return allUrlPathnames;
}

// domainsGroups: [{ user_id, group_uid, domain_name, is_connected }]
// returns: [ [{ user_id, group_uid, domain_name }] ]
function normalizeDomainsGroups(domainsGroups) {
  return (0, _values.default)((0, _groupBy.default)(domainsGroups, 'group_uid')).map(function (dgList) {
    return dgList.map(function (dg) {
      return _objectSpread(_objectSpread({}, dg), {}, {
        domain_name: normaliseDomain(dg.domain_name, {
          removeWWW: true
        }),
        is_connected: !!dg.is_connected
      });
    });
  });
}

// NOTE: hostname must be normalized (no www)
function getDomainsGroupMatch(hostname, dgList) {
  return (0, _orderBy.default)(dgList.filter(function (dg) {
    return dg.is_connected && (hostname === dg.domain_name || hostname.endsWith(".".concat(dg.domain_name)));
  }), function (dg) {
    return dg.domain_name.split('.').length;
  }, 'desc')[0];
}
function applyAlias(urls, domainsGroups) {
  var normalizedGroups = normalizeDomainsGroups(domainsGroups);
  var parsedUrls = urls.map(parseUrl);
  return (0, _uniq.default)((0, _flatten.default)(parsedUrls.filter(function (u) {
    return u.hostname;
  }).map(function (u, i) {
    var hostname = normaliseDomain(u.hostname, {
      removeWWW: true
    });
    var hostnameParts = hostname.split('.');
    return (0, _flatten.default)(normalizedGroups.map(function (dgList) {
      var groupMatch = getDomainsGroupMatch(hostname, dgList);
      if (groupMatch) {
        // replace url with all the domains in the group
        var groupMatchParts = groupMatch.domain_name.split('.');
        return dgList.map(function (dg) {
          return _url.default.format(_objectSpread(_objectSpread({}, u), {}, {
            host: hostnameParts.slice(0, hostnameParts.length - groupMatchParts.length).concat([dg.domain_name]).join('.')
          }));
        });
      }
      return [];
    })).concat([urls[i]]);
  })));
}
function isLocalhostIp(ip) {
  return ip === '127.0.0.1' || ip === '::ffff:127.0.0.1' || ip === '::1';
}
function appendQuery(url, query) {
  if ((0, _isObject.default)(query)) {
    query = _querystring.default.stringify(query);
  }
  if (!query) return url;
  var hash = '';
  var hashIndex = url.indexOf('#');
  if (hashIndex !== -1) {
    // remove hash (add it back later)
    hash = url.slice(hashIndex);
    url = url.slice(0, hashIndex);
  }
  return url + (url.indexOf('?') === -1 ? '?' : '&') + query + hash;
}
function extendQuery(url, newQuery) {
  if ((0, _isObject.default)(newQuery)) {
    newQuery = _querystring.default.stringify(newQuery);
  }
  if (!newQuery) return url;
  var hash = '';
  var hashIndex = url.indexOf('#');
  if (hashIndex !== -1) {
    // remove hash (add it back later)
    hash = url.slice(hashIndex);
    url = url.slice(0, hashIndex);
  }
  var query = '';
  var queryIndex = url.indexOf('?');
  if (queryIndex !== -1) {
    // remove query (extend it and add it back later)
    query = url.slice(queryIndex + 1); // +1 to select without initial "?"
    url = url.slice(0, queryIndex);
  }
  var extendedQuery = queryParser(_objectSpread(_objectSpread({}, parseQuery('?' + query)), parseQuery('?' + newQuery)));
  return url + extendedQuery + hash;
}

// NOTE: newHash replaces current hash.
// An empty/falsy newHash will actually remove the hash completely
// (# included)
function appendHash(url, newHash) {
  newHash = newHash || '';
  if (newHash && newHash[0] !== '#') newHash = '#' + newHash;
  var hashIndex = url.indexOf('#');
  if (hashIndex !== -1) {
    url = url.slice(0, hashIndex);
  }
  return url + newHash;
}
function appendToUrl(url, data) {
  if ('query' in data) {
    url = appendQuery(url, data.query);
  }
  if ('hash' in data) {
    url = appendHash(url, data.hash);
  }
  return url;
}
var hostnameToParents = exports.hostnameToParents = function hostnameToParents(hostname) {
  var results = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var firstDotIndex = hostname.indexOf('.');
  var lastDotIndex = hostname.lastIndexOf('.');
  if (firstDotIndex === lastDotIndex && firstDotIndex !== hostname.length - 1) {
    return [].concat(_toConsumableArray(results), [hostname]);
  }
  return hostnameToParents(hostname.substring(firstDotIndex + 1), [].concat(_toConsumableArray(results), [hostname]));
};

// convert url to page name
var getPageName = exports.getPageName = (0, _flowRight.default)(function (s) {
  return parseUrl(s).hostname || '';
}, function (s) {
  return s.replace(/^www./, '');
},
// remove wwww
function (s) {
  return s.replace(/\.[^\.]+$/, '');
} // remove top
);

// used for creating short links
var possibleChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']; // eslint-disable-line comma-spacing
function encodeShortLinkId(id) {
  id = parseInt(id, 10);
  var result = '';
  var temp = id;
  var totalNumber = possibleChars.length;
  while (temp >= totalNumber) {
    result = possibleChars[temp % totalNumber] + result;
    temp = Math.floor(temp / totalNumber);
  }
  result = possibleChars[temp % totalNumber] + result;
  return result;
}
function decodeShortLinkId(encodedId) {
  var result = 0;
  var totalNumber = possibleChars.length;
  if (encodedId.length > 0) {
    while (encodedId) {
      var index = possibleChars.indexOf(encodedId[0]);
      var power = encodedId.length - 1;
      result += index * Math.pow(totalNumber, power);
      encodedId = encodedId.substring(1);
    }
  }
  return result;
}
var getSpecialPlanLink = exports.getSpecialPlanLink = function getSpecialPlanLink(planId) {
  return isLive ? 'ior.ad/p/' + encodeShortLinkId(planId) : node('acceptSpecial', {
    publicPlanId: planId
  }, 'on');
};
var matchPlanShortLink = exports.matchPlanShortLink = function matchPlanShortLink(string) {
  return string.match(new RegExp('(?<=^|https?://)ior\\.ad/p/(.*)$'));
};
var matchPlayerShortLink = exports.matchPlayerShortLink = function matchPlayerShortLink(string) {
  return string.match(new RegExp('^https?://ior\\.ad/([a-zA-Z0-9]+)(\\?|$)'));
};
var getRelativePlayerUrl = exports.getRelativePlayerUrl = function getRelativePlayerUrl(tutorialId, tutorialName) {
  var normalizedTutorialName = (0, _normalizeTutorialName.default)(tutorialName);
  return "/player/".concat(tutorialId, "/").concat(normalizedTutorialName);
};
var getAbsolutePlayerUrl = exports.getAbsolutePlayerUrl = function getAbsolutePlayerUrl() {
  return base(getRelativePlayerUrl.apply(void 0, arguments), null, true);
};
var extractTutorialIdFromUrl = exports.extractTutorialIdFromUrl = function extractTutorialIdFromUrl(value) {
  var playerShortLinkMatch = matchPlayerShortLink(value);
  if (playerShortLinkMatch) {
    return decodeShortLinkId(playerShortLinkMatch[1]);
  }
  var PLAYER_URL_REGEXP = new RegExp("".concat((0, _escapeRegExp.default)(base('/player')), "/(\\d+)"));
  var tutorialId = parseInt((value.match(PLAYER_URL_REGEXP) || [])[1], 10);
  return tutorialId;
};
function getQuestionURL(_ref2) {
  var questionId = _ref2.questionId,
    questionName = _ref2.questionName,
    productName = _ref2.productName,
    productLabel = _ref2.productLabel,
    productRoleId = _ref2.productRoleId,
    productHelpCenterUrlId = _ref2.productHelpCenterUrlId,
    answerId = _ref2.answerId,
    _ref2$expanded = _ref2.expanded,
    expanded = _ref2$expanded === void 0 ? false : _ref2$expanded,
    _ref2$helpCenterShare = _ref2.helpCenterShare,
    helpCenterShare = _ref2$helpCenterShare === void 0 ? false : _ref2$helpCenterShare,
    _ref2$relative = _ref2.relative,
    relative = _ref2$relative === void 0 ? false : _ref2$relative;
  if (helpCenterShare && productHelpCenterUrlId) {
    // fake short URL for question URL embeded inside library
    var _relativeUrl = '/s/hq/' + [productHelpCenterUrlId, productRoleId || '0', questionId].concat(_toConsumableArray(answerId ? [answerId] : [])).map(function (val) {
      return encodeShortLinkId(val);
    }).join('/');
    if (!expanded) {
      _relativeUrl += '?c'; // c: collapsed (not expanded)
    }
    if (relative) return _relativeUrl;
    var _absoluteUrl = base(_relativeUrl, null, true);
    return _absoluteUrl;
  }

  // regular question URL
  var relativeUrl = "".concat(productName ? "/product/".concat(productName) : '', "/thesquare/questions/").concat(questionId, "/").concat((0, _normalizeTutorialName.default)((productLabel ? productLabel + ' ' : '') + questionName)).concat(answerId ? "/answers/".concat(answerId) : '');
  if (expanded) {
    relativeUrl += "?fixedQuestionIdExpanded=".concat(questionId);
  }
  if (relative) return relativeUrl;
  var absoluteUrl = base(relativeUrl, null, true);
  return absoluteUrl;
}
function getProductQuestionsURL(_ref3) {
  var productId = _ref3.productId,
    productRoleId = _ref3.productRoleId,
    productHelpCenterUrlId = _ref3.productHelpCenterUrlId,
    _ref3$helpCenterShare = _ref3.helpCenterShare,
    helpCenterShare = _ref3$helpCenterShare === void 0 ? false : _ref3$helpCenterShare,
    _ref3$relative = _ref3.relative,
    relative = _ref3$relative === void 0 ? false : _ref3$relative;
  var relativeUrl;
  if (helpCenterShare && productHelpCenterUrlId) {
    relativeUrl = "/help-center/".concat(productHelpCenterUrlId, "/").concat(queryParser(_objectSpread({}, productRoleId ? {
      roleId: productRoleId
    } : {})));
  } else {
    relativeUrl = "/thesquare/questions?productId=".concat(productId, "&resetFilters=1");
  }
  if (relative) return relativeUrl;
  var absoluteUrl = base(relativeUrl, null, true);
  return absoluteUrl;
}
function redirectToSamlUrl(samlUrl) {
  // used on regular saml login in https://iorad.com/login/saml
  if (window.self === window.top) {
    window.location.href = samlUrl;
    return;
  }
  try {
    // used on thesquare/ioradicals login in https://dev.iorad.dev/thesquare/ioradicals
    if (window.top.location && window.top.location.hostname === window.self.location.hostname) {
      window.open(samlUrl, '_top');
      return;
    }
  } catch (e) {
    // do nothing. can't access parent window. so open new tab;
  }
  var popupWidth = Math.min(window.screen.width, 450);
  var popupHeight = Math.min(window.screen.height, 600);
  var popupLeft = Math.floor(window.screen.width / 2 - popupWidth / 2);
  var popupTop = Math.floor(window.screen.height / 2 - popupHeight / 2);
  var requestId = String(Date.now());
  window.open(samlUrl, 'saml_login_page', ['menubar=no', 'toolbar=no', 'location=no', 'status=yes', 'resizable=yes', 'scrollbars=yes', 'noopener=no', "width=".concat(popupWidth), "height=".concat(popupHeight), "top=".concat(popupTop), "left=".concat(popupLeft)].join(','));
}
var getCorrectUrl = exports.getCorrectUrl = function getCorrectUrl(url) {
  return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('mailto:') ? url : 'https://' + url;
};