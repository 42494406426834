"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
// common config for both api and web app

var trim = require('lodash/trim');
var isNil = require('lodash/isNil');
var boolean = require('boolean');
var processEnv = require('./process-env');
function requiredValidate(key, value) {
  if (value == null || trim(value) == '') {
    throw new Error("".concat(key, " env var must be set!"));
  }
  return value;
}
function validatedEnv(key, validate) {
  validate = validate || requiredValidate;
  return validate(key, processEnv[key]);
}
function nodeValidatedEnv(key, validate) {
  var value = processEnv[key];
  if (typeof window !== 'undefined') {
    return value;
  }
  validate = validate || requiredValidate;
  return validate(key, value);
}
var host = processEnv.HOST || 'dev.iorad.dev';
var commonConfig = {
  host: host,
  isProduction: !(processEnv.NODE_ENV === 'development' || host === 'test.iorad.com' || host === 'develop.iorad.dev'),
  isDevDebug: processEnv.NODE_ENV === 'development' && processEnv.DEV_DEBUG === 'true',
  port: processEnv.PORT,
  cronHost: processEnv.CRON_HOST,
  isFirstPM2Instance: processEnv.DEPLOYMENT_NAME === 'cron' ? true : host.endsWith('.iorad.dev') && _typeof(!processEnv.NODE_APP_INSTANCE) ? true : processEnv.NODE_APP_INSTANCE === '0',
  apiHost: processEnv.API_HOST || 'localhost',
  apiPort: processEnv.API_PORT || 3030,
  cdnHost: processEnv.CDN_HOST || 'cdn.iorad.dev',
  webpackHost: processEnv.WEBPACK_HOST || 'webpack.iorad.dev',
  mailerEmailHost: processEnv.MAILER_EMAIL_HOST || 'iorad.com',
  extensionStartHost: processEnv.EXTENSION_START_HOST || 'start.iorad.com',
  videoHost: processEnv.VIDEO_HOST || 'video.iorad.com',
  crawlerHost: processEnv.CRAWLER_HOST || 'crawler.iorad.com',
  landingHost: processEnv.LANDING_HOST || 'landing.iorad.com',
  statusHost: processEnv.STATUS_HOST || 'status.iorad.com',
  stripeEnv: processEnv.STRIPE_ENV || 'test',
  stripeApiVersion: processEnv.STRIPE_API_VERSION || '2024-11-20.acacia',
  defaultMonthlyPlan: parseInt(processEnv.DEFAULT_MONTHLY_PLAN || 1, 10),
  defaultYearlyPlan: parseInt(processEnv.DEFAULT_YEARLY_PLAN || 2, 10),
  intercomId: processEnv.INTERCOM_ID || 'none',
  hubspotId: processEnv.HUBSPOT_ID,
  hubspotTrackingCodeHostname: processEnv.HUBSPOT_TRACKING_CODE_HOSTNAME || 'js.hs-scripts.com',
  liveEmbedAssetsWebPath: processEnv.LIVE_EMBED_ASSETS_WEB_PATH,
  // TODO: cannot use nodeValidatedEnv for this in local dev (docker)
  liveEmbedScript: processEnv.LIVE_EMBED_SCRIPT,
  // TODO: unused?
  cacheMaxAge: parseInt(processEnv.CACHE_MAX_AGE || 0, 10),
  // in sec, for cache control on public pages
  googleAnalyticsSiteId: processEnv.GOOGLE_ANALYTICS_SITE_ID,
  googleClientId: processEnv.GOOGLE_CLIENT_ID,
  googleExtensionId: processEnv.GOOGLE_EXTENSION_ID,
  gdriveClientId: processEnv.GDRIVE_CLIENT_ID,
  gdrivePickerApiKey: processEnv.GDRIVE_PICKER_API_KEY,
  intercomAppClientId: processEnv.INTERCOM_APP_CLIENT_ID,
  intercomAppStoreLink: processEnv.INTERCOM_APP_STORE_LINK,
  recaptchaV2SiteKey: processEnv.RECAPTCHA_V2_SITE_KEY,
  recaptchaV3SiteKey: validatedEnv('RECAPTCHA_V3_SITE_KEY'),
  includeLiveEmbed: !isNil(processEnv.INCLUDE_LIVE_EMBED) ? boolean(processEnv.INCLUDE_LIVE_EMBED) : true,
  liveEmbedUserId: processEnv.LIVE_EMBED_USER_ID || 11,
  liveEmbedTutorialId: processEnv.LIVE_EMBED_TUTORIAL_ID || 77041,
  liveEmbedDefaultOptions: {
    colors: {
      main: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_MAIN || '#1d1f23',
      mainFont: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_MAIN_FONT || '',
      closeButton: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_CLOSE_BUTTON || '#000000',
      closeButtonFont: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_CLOSE_BUTTON_FONT || '',
      navBar: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_NAV_BAR || '#1d1f23',
      // DEPRECATED
      actionButton: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_ACTION_BUTTON || '#FF00D6',
      // DEPRECATED
      highlight: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_HIGH_LIGHT || '#FF00D6',
      font: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COLORS_FONT || '#FF00D6'
    },
    combineHubspot: boolean(processEnv.LIVE_EMBED_DEFAULT_OPTIONS_COMBINE_HUBSPOT) || false,
    hideIcon: boolean(processEnv.LIVE_EMBED_DEFAULT_OPTIONS_HIDE_ICON) || false,
    showTutorialsCount: boolean(processEnv.LIVE_EMBED_DEFAULT_OPTIONS_SHOW_TUTORIALS_COUNT) || false,
    showAllTutorials: boolean(processEnv.LIVE_EMBED_DEFAULT_OPTIONS_SHOW_ALL_TUTORIALS) || false,
    showOnMobile: boolean(processEnv.LIVE_EMBED_DEFAULT_OPTIONS_SHOW_ON_MOBILE) || false,
    defaultView: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_DEFAULT_VIEW || 'categories',
    defaultTab: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_DEFAULT_TAB || 'categories',
    position: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_POSITION || 'br',
    primaryFontFamily: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_PRIMARY_FONT_FAMILY || 'Libre Franklin',
    secondaryFontFamily: processEnv.LIVE_EMBED_DEFAULT_OPTIONS_SECONDARY_FONT_FAMILY || 'Fahkwang'
  },
  microTrackingEnabled: boolean(processEnv.MICRO_TRACKING_ENABLED),
  app: {
    title: 'iorad - the tutorial builder',
    description: 'Instantly make guided, step-by-step directions for any website directly in the browser. iorad is the quickest and easiest tutorial creator on the web.',
    head: {
      titleTemplate: '%s',
      meta: [{
        charset: 'utf-8'
      }, {
        'http-equiv': 'X-UA-Compatible',
        content: 'IE=edge, chrome=1'
      }, {
        name: 'description',
        content: 'Instantly make guided, step-by-step directions for any website directly in the browser. iorad is the quickest and easiest tutorial creator on the web.'
      }, {
        name: 'google-site-verification',
        content: 'bJwiKI9CRA_Mi9p1Fz1s6ZT8Zu9D1x9xndjFrPIHLls'
      }, {
        name: 'apple-mobile-web-app-capable',
        content: 'yes'
      }, {
        name: 'apple-mobile-web-app-status-bar-style',
        content: 'black'
      }, {
        name: 'p:domain_verify',
        content: '143ebb8333e460da7e38e0e007c07af4'
      }, {
        name: 'slack-app-id',
        content: processEnv.SLACK_APP_ID || 'A9MELHXE3'
      }, {
        property: 'og:site_name',
        content: 'iorad - the tutorial builder'
      }, {
        property: 'og:image',
        content: "https://".concat(host, "/logo.png")
      }, {
        property: 'og:image:width',
        content: '512'
      }, {
        property: 'og:image:height',
        content: '512'
      }, {
        property: 'og:locale',
        content: 'en_US'
      }, {
        property: 'og:title',
        content: 'iorad - the tutorial builder'
      }, {
        property: 'og:description',
        content: 'Instantly make guided, step-by-step directions for any website directly in the browser. iorad is the quickest and easiest tutorial creator on the web.'
      }, {
        property: 'og:card',
        content: 'summary'
      }, {
        property: 'og:site',
        content: '@iorad'
      }, {
        property: 'og:creator',
        content: '@iorad'
      }]
    }
  },
  liveEmbedCode: function liveEmbedCode(urlUtils, userId) {
    return "<script defer src=\"".concat(urlUtils.liveEmbed(userId), "\"></script>");
  },
  tutorialDataBasePath: nodeValidatedEnv('TUTORIAL_DATA_BASE_PATH'),
  sitemapsBasePath: nodeValidatedEnv('SITEMAPS_BASE_PATH'),
  avatarsBasePath: nodeValidatedEnv('AVATARS_BASE_PATH'),
  faviconsBasePath: nodeValidatedEnv('FAVICONS_BASE_PATH'),
  productsBasePath: nodeValidatedEnv('PRODUCTS_BASE_PATH'),
  cacheBasePath: nodeValidatedEnv('CACHE_BASE_PATH'),
  awsTutorialDataBasePath: processEnv.AWS_TUTORIAL_DATA_BASE_PATH,
  // TODO: required in node if cloud enabled
  awsSitemapsBasePath: processEnv.AWS_SITEMAPS_BASE_PATH,
  awsAvatarsBasePath: processEnv.AWS_AVATARS_BASE_PATH,
  awsFaviconsBasePath: processEnv.AWS_FAVICONS_BASE_PATH,
  awsProductsBasePath: processEnv.AWS_PRODUCTS_BASE_PATH,
  awsCacheBasePath: processEnv.AWS_CACHE_BASE_PATH,
  cryptoTutorialDataBasePath: processEnv.CRYPTO_TUTORIAL_DATA_BASE_PATH,
  // TODO: required in node if crypto enabled
  cloudfrontExpireTime: process.env.AWS_CLOUDFRONT_EXPIRE_TIME || 24
};
module.exports = {
  config: commonConfig,
  validatedEnv: validatedEnv
};